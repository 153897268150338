<script>
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import { overlay } from "@/state/helpers";
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import SettingsSide  from "@/components/settings-side.vue";
  import EmptyTableResult from "@/components/empty-table-result";
  import appConfig from "@/../app.config";
  import Swal from "sweetalert2";
  import { callUrl } from '@/helpers/api-apolo';
  import { saError } from '@/helpers/global-functions';
  import * as helpers from '@/helpers/helper-functions';

  export default {
    page: {
      title: "Integrações",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        title: "Integrações",
        items: [{
            text: "Configurações",
            href: "/"
          },
          {
            text: "Integrações",
            active: true
          },
        ],
        entityNames: {
            "Account": "Conta",
            "Pipeline": "Pipeline",
            "Business": "Negócio",
            "Person": "Pessoa",
            "Product": "Produto",
            "Task": "Tarefa",
            "User": "Usuário"
        },
        actionNames: {
            "Create": "Criação",
            "Update": "Atualização",
            "Delete": "Remoção"
        },
        authenticationTypeNames: {
          'None': 'Nenhuma',
          'Basic': 'Basic Authentication',
          'ApiKey': 'ApiKey Token'
        },
        filters: {
          query: '',
          pipeline_id: '',
          entity: ''
        },
        page: 1,
        perPage: 10,
        pipelines: [],
        dataList: [],
        activeHook: {
            entity: [],
            action: [],
            pipeline_id: '',
            url: '',
            authentication_type: '',
            authentication_pass: ''
        }
      }
    },
    components: {
      Layout,
      PageHeader,
      Multiselect,
      EmptyTableResult,
      SettingsSide
    },
    computed: {
      pages() {
        if (this.page == 1 && !this.dataList.length) {
          return [];
        }
        if (this.page == 1 && this.dataList.length < this.perPage) {
          return [1];
        }
        if (this.page == 1 && this.dataList.length == this.perPage) {
          return [1, 2];
        }
        if (this.page > 1 && (!this.dataList.length || this.dataList.length < this.perPage)) {
          return [this.page - 1, this.page];
        }
        return [this.page - 1, this.page, this.page + 1];
      }
    },
    watch: {
      page() {
        this.fetchData();
      }
    },
    methods: {
      ...overlay,
      ...helpers,
      translateEntitys(values) {
        if (values.length === 6) {
            return 'Todos'
        }
        return values.map(value => this.entityNames[value]||value).join(', ');
      },
      translateActions(values) {
        if (values.length === 3) {
            return 'Todas'
        }
        return values.map(value => this.actionNames[value]||value).join(', ');
      },
      search() {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.fetchData();
        }
      },
      async fetchData() {
        try {
          this.changeShowOverlay({show: true});
          this.dataList = [];
          const params = [`page=${this.page}`, `limit=${this.perPage}`, `order=created_at:1`];
          if (this.filters.query.length > 2) {
            params.push(`query=${this.filters.query}`);
          }
          if (this.filters.entity?.length > 0) {
            params.push(`entity=${this.filters.entity}`);
          }
          if (this.filters.pipeline_id?.length > 0) {
            params.push(`pipeline_id=${this.filters.pipeline_id}`);
          }
          const dataM = await callUrl({}, `/hook?${params.join('&')}`, 'get');
          if (dataM && dataM.length) {
            this.dataList = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
        this.changeShowOverlay({show: false});
      },
      async getAllPipelines() {
        try {
          this.pipelines = [];
          const dataM = await callUrl({}, '/pipeline', 'get')
          if (dataM && dataM.length) {
            this.pipelines = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
      },
      async addNewHook() {
        if (!this.activeHook.entity.length) {
          saError('Ao menos uma entidade precisa ser selecionada');
          return false;
        }
        if (!this.activeHook.action.length) {
          saError('Ao menos uma ação precisa ser selecionada');
          return false;
        }
        if (!this.activeHook.url.length) {
          saError('A Url da integração é obrigatória');
          return false;
        }
        if (!/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig.test(this.activeHook.url)) {
          saError('A Url da integração é inválida');
          return false;
        }
        if (!this.activeHook.authentication_type.length) {
          saError('O tipo de autenticação da integração é obrigatória');
          return false;
        }
        if (this.activeHook.authentication_type !== 'None' && !this.activeHook.authentication_pass.length) {
          saError('O tipo de autenticação escolhido precisa de uma senha');
          return false;
        }
        try {
          this.changeShowOverlay({show: true});
          await callUrl(this.activeHook, '/hook', 'post')
          this.changeShowOverlay({show: false});
          Swal.fire("Sucesso!", "Integração criada com sucesso", "success");
          document.getElementById('closemodal').click();
          this.search();
        } catch (error) {
          this.changeShowOverlay({show: false});
          saError(error.message)
        }
      },
      async deletedata(event) {
        Swal.fire({
          title: "Você tem certeza que deseja remover esta integração?",
          text: "Não será possível reverter esta ação!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, remover!",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
          buttonsStyling: false,
          customClass: {
            actions: 'd-flex justify-content-center',
            confirmButton: 'btn btn-success flex-grow-1',
            cancelButton: 'btn btn-outline-dark flex-grow-1'
          }
        }).then(async (result) => {
          if (result.value) {
            try {
              this.changeShowOverlay({show: true});
              await callUrl({}, '/hook/' + event.id, 'delete');
              this.changeShowOverlay({show: false});
              Swal.fire("Sucesso!", "Integração removida com sucesso", "success");
              this.search();
            } catch (error) {
              this.changeShowOverlay({show: false});
              saError(error.message);
            }
          }
        });
      },
      addNew() {
        this.activeHook = {
            entity: [],
            action: [],
            pipeline_id: '',
            url: '',
            authentication_type: '',
            authentication_pass: ''
        }
      },
      viewItem(item) {
        this.activeHook = item
      }
    },
    mounted() {
        this.getAllPipelines();
        this.fetchData();
    }
  };
</script>

<style scoped>
@media only screen and (max-width: 500px) {
    .card-header h5 { display: none; }
}
@media only screen and (max-width: 390px) {
    .card-header button i { display: none; }
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-sm-12 col-lg-2 col-md-3">
          <SettingsSide :actualRoute="'integrations'"></SettingsSide>
        </div>
        <div class="col-sm-12 col-lg-10 col-md-9">
          <div class="card" id="customFieldList">
            <div class="card-header border-0">
              <div class="d-flex align-items-center">
                <h5 class="card-title mb-0 flex-grow-1">Integrações</h5>
                <div class="flex-shrink-0">
                  <router-link :to="{ name: 'hook-executed-list' }">Logs de Integração</router-link>
                  <button class="btn btn-success add-btn ms-3" data-bs-toggle="modal" href="#showmodal" @click="addNew">
                    <i class="ri-add-line align-bottom me-1"></i> Criar nova Integração
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body bg-soft-light border border-dashed border-start-0 border-end-0">
            <form>
              <div class="row g-3">
                <div class="col-sm-6 col-md-4">
                  <div class="search-box">
                    <input type="text" class="form-control search" v-model="filters.query" placeholder="Busca por algum termo...">
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>

                <div class="col-sm-6 col-md-3">
                  <div class="input-light">
                    <Multiselect class="form-control" v-model="filters.entity" :close-on-select="true" :searchable="true"
                      :create-option="true"
                      :options="[{ value: '', label: 'Todas entidades' }].concat(Object.keys(entityNames).map(key => ({value:key, label:entityNames[key]})))" />
                  </div>
                </div>

                <div class="col-sm-6 col-md-3">
                  <div class="input-light">
                    <Multiselect class="form-control" v-model="filters.pipeline_id" :close-on-select="true" :searchable="true"
                      :create-option="true"
                      :options="[{ value: '', label: 'Todos pipelines' }].concat(pipelines.map(pipeline => ({value:pipeline.id, label:pipeline.name})))" />
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <button type="button" class="btn btn-primary w-100" @click="search()">
                    <i class="mdi mdi-magnify me-1 align-bottom"></i> Buscar
                  </button>
                </div>
              </div>
            </form>
          </div>
            <div class="card-body">
            <div>
              <div class="table-responsive table-card">
                <table class="table table-striped align-middle table-nowrap">
                  <thead class="table-primary">
                    <tr>
                      <th class="sort text-uppercase">ID</th>
                      <th class="sort text-uppercase">Pipeline</th>
                      <th class="sort text-uppercase">Entidades</th>
                      <th class="sort text-uppercase">Ações</th>
                      <th class="sort text-uppercase">Url</th>
                      <th class="sort text-uppercase">Criado em</th>
                      <th class="sort text-uppercase">Ações</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of dataList" :key="index">
                      <td>{{item.code}}</td>
                      <td><router-link :to="{ name: 'crm-deals' }">{{item.pipeline_id}}</router-link></td>
                      <td>{{translateEntitys(item.entity)}}</td>
                      <td>{{translateActions(item.action)}}</td>
                      <td>{{limitString(item.url, 50)}}</td>
                      <td>{{translateDateOnly(item.created_at)}} <small class="text-muted">{{translateTimeOnly(item.created_at)}}</small></td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-soft-secondary btn-sm dropdown" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="ri-more-fill align-middle"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <a class="dropdown-item" data-bs-toggle="modal" href="#showmodal" @click.prevent="viewItem(item)" style="cursor:pointer">
                                <i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Visualizar
                              </a>
                            </li>
                            <li class="dropdown-divider"></li>
                            <li>
                              <a class="dropdown-item remove-item-btn" @click.prevent="deletedata(item)" style="cursor:pointer">
                                <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Remover
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <EmptyTableResult v-show="dataList.length == 0" />

              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev" href="#" @click="--page" v-if="page > 1">Anterior</a>
                  <a class="page-item pagination-prev disabled" href="#" v-if="dataList.length && page == 1" @click.prevent="">Anterior</a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li v-for="pageNumber in pages" :key="'page' + pageNumber" @click="page = pageNumber"
                      :class="{active: pageNumber == page, disabled: pageNumber != page}">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="++page" v-if="dataList.length == perPage">Próxima</a>
                  <a class="page-item pagination-next disabled" href="#" @click.prevent="" v-if="dataList.length && dataList.length < perPage">Próxima</a>
                </div>
              </div>

                <!-- Modal -->
                <div class="modal fade zoomIn" id="showmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content border-0">
                            <div class="modal-header p-3 bg-soft-info">
                                <h5 v-if="!activeHook.id" class="modal-title" id="exampleModalLabel">Criar nova Integração</h5>
                                <h5 v-if="activeHook.id" class="modal-title" id="exampleModalLabel">Integração {{activeHook.code}}</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
                            </div>
                            <form id="addform">
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div>
                                                <label class="form-label d-block">Entidade(s) *</label>
                                                <div v-for="(item, index) of Object.keys(entityNames)" :key="index" class="form-check form-check-inline">
                                                    <label class="form-check-label">
                                                        <input :class="[{'fieldDisabled': activeHook.id?.length > 0}]" :disabled="activeHook.id?.length > 0" v-model="activeHook.entity" class="form-check-input" type="checkbox" :value="item"> {{entityNames[item]}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-6">
                                            <div>
                                                <label class="form-label d-block">Ação *</label>
                                                <div v-for="(item, index) of Object.keys(actionNames)" :key="index" class="form-check form-check-inline">
                                                    <label class="form-check-label">
                                                        <input :class="[{'fieldDisabled': activeHook.id?.length > 0}]" :disabled="activeHook.id?.length > 0" v-model="activeHook.action" class="form-check-input" type="checkbox" :value="item"> {{actionNames[item]}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <label for="hookPipeline" class="form-label">Pipeline</label>
                                            <Multiselect :class="[{'fieldDisabled': activeHook.id?.length > 0}]" :disabled="activeHook.id?.length > 0" id="hookPipeline" v-model="activeHook.pipeline_id" :close-on-select="true" :searchable="true" :create-option="false"
                                                :options="[].concat(pipelines.map(pipeline => ({value:pipeline.id,label:pipeline.name})))"
                                                placeholder="Pipeline onde a integração estará ativa" />
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-12">
                                            <label for="hookUrl" class="form-label">Url *</label>
                                            <input :class="[{'fieldDisabled': activeHook.id?.length > 0}]" :disabled="activeHook.id?.length > 0" type="text" v-model="activeHook.url" id="hookUrl" class="form-control" placeholder="Url da integração" />
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <label for="hookAuthenticationType" class="form-label">Tipo de Autenticação *</label>
                                            <Multiselect :class="[{'fieldDisabled': activeHook.id?.length > 0}]" :disabled="activeHook.id?.length > 0" id="hookPipeline" v-model="activeHook.authentication_type" :close-on-select="true" :searchable="true" :create-option="false"
                                                :options="[].concat(Object.keys(authenticationTypeNames).map(key => ({value:key,label:authenticationTypeNames[key]})))"
                                                placeholder="Tipo de autenticação" />
                                        </div>
                                        <div class="col-lg-6">
                                            <label for="hookAuthenticationPass" class="form-label">Senha da Autenticação</label>
                                            <input :class="[{'fieldDisabled': activeHook.id?.length > 0}]" :disabled="activeHook.id?.length > 0" type="text" v-model="activeHook.authentication_pass" id="hookAuthenticationPass" class="form-control" placeholder="Senha da autenticação" />
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer ">
                                    <div class="hstack gap-2 justify-content-end">
                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="closemodal">Fechar</button>
                                        <button v-if="!activeHook.id" type="submit" class="btn btn-success" id="add-btn" @click.prevent="addNewHook">Criar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!--end modal-->
            </div>

          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>
